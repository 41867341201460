<template>
  <main>
    <!-- application form account-info change -->
    <section id="register_points_search_upload">
      <div class="w1300 layout_container">

        <!-- 表單主標題 -->
        <div class="main_title_box">
          <h3 class="main_title txt-bold">申請者回傳成果報告</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- form area -->
        <div class="form_box">
          <form id="form-register_points_search_upload.php" @submit.prevent>

            <div class="form_interactive mg-b-50">
              <Upload
                class="w50"
                v-model="formData.files.sign_copy.file"
                :item="inputFormat.sign_copy_upload"
                :rules="[
                  val => !!val || '請上傳檔案!',
                ]"
                :fileTitle.sync="formData.files.sign_copy.title"
              />
              <Upload
                class="w50"
                v-model="formData.files.sign_import.file"
                :item="inputFormat.sign_import_upload"
                :rules="[
                  val => !!val || '請上傳檔案!',
                ]"
                :fileTitle.sync="formData.files.sign_import.title"
              />
              <Upload
                class="w50"
                v-model="formData.files.class_pager.file"
                :item="inputFormat.class_pager_upload"
                :rules="[
                  val => !!val || '請上傳檔案!',
                ]"
                :fileTitle.sync="formData.files.class_pager.title"
              />
              <Password
                class="w100"
                v-model="formData.password"
                :item="inputFormat.passwordInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />
            </div>

            <div class="form_submit txt-right">
              <button
                type="button"
                class="btn btn-noIcon btn-light_green p"
                @click="onSubmit"
              >送出</button>
              <router-link to="/register/point/search" class="btn btn-noIcon btn-dark_gray p"
              >返回</router-link>
            </div>

          </form>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
import Upload from '@/components/form/Upload.vue';
import Password from '@/components/form/Password.vue';
import { mapActions } from 'vuex';
import _ from 'lodash';
import { eventsUpload } from '@/lib/http';

export default {
  name: 'RegisterPointSearchUpload',
  data() {
    return {
      formData: {
        uuid: '',
        password: '',
        files: {
          sign_copy: {
            title: '',
            file: null,
          },
          sign_import: {
            title: '',
            file: null,
          },
          class_pager: {
            title: '',
            file: null,
          },
        },
      },
      inputFormat: {
        sign_copy_upload: {
          label: '活動簽名單掃描檔',
          subLabel: '格式 pdf。限制大小：4MB',
          accept: '.pdf',
          size: 4 * 1024 * 1024,
          required: true,
        },
        sign_import_upload: {
          label: '積分表格檔',
          subLabel: '格式 xls/xlsx。限制大小：4MB',
          accept: '.xls, .xlsx',
          size: 4 * 1024 * 1024,
          required: true,
        },
        class_pager_upload: {
          label: '授課講義',
          subLabel: '格式 zip/pdf。限制大小：4MB',
          accept: '.zip, .pdf',
          size: 4 * 1024 * 1024,
          required: true,
        },
        passwordInput: {
          label: '自設密碼',
          reminder: '',
          placeholder: '請輸入您的自設密碼',
          required: true,
        },
      },
    };
  },
  created() {
    const eventUUID = _.get(this.$route, 'query.uuid', '');
    if (eventUUID) {
      this.formData.uuid = eventUUID;
    } else {
      this.openModal({
        message: '尚未選擇積分申請資料',
        url: '/register/point/search',
      });
    }
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        eventsUpload(this.formData).then((result) => {
          if (result.data.status) {
            this.openModal({
              // message: result.data.message,
              message: '上傳成功',
              url: '/register/point/search',
            });
          } else {
            this.openModal({
              message: result.data.message,
              // url: '/register/point/search',
            });
          }
        });
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
  },
  components: {
    Upload,
    Password,
  },
};
</script>
